import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class MachineService {
    readQuery(){
        var query = `query{
            GetMachine{
                machine_id
                machine_name
                machine_type
                active_flag
                created_at
              }
        }`;
        return query;
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
            DeleteMachine(machineId:$id){
                response
            }
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        var query = gql`mutation($data: NewMachine!){
            CreateMachine(input:$data){
                response
            }
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewMachine!){
            UpdateMachine(machineId:$id,New:$data){
                response
            }
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new MachineService();