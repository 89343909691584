<template>
    <div>
        <div class="modal fade" id="machineModal" tabindex="-1" aria-labelledby="machineModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="machineModalLabel" class="font-weight-bold">{{ModalTitle}}{{MachineName}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="MachineName" v-model="MachineName" class="font-weight-bold" />
                                    <label id="errorMachineName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Tipe Mesin</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="MachineTypeId" v-model="MachineTypeId" class="font-weight-bold" />
                                    <label id="errorMachineTypeId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="ActiveFlag" class="font-weight-bold" :options="ActiveFlagData" :value.sync="ActiveFlag" />
                                    <label id="errorActiveFlag" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import machineServices from '../Script/MachineServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'MachineForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',

            MachineId : null,
            MachineName : '',
            MachineTypeId : '',
            ActiveFlag : '',
            ActiveFlagData : [],

            Error: 0,
            SaveType: '',
        }
    },
    mounted(){

    },
    methods: {
        addClick(){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add Machine: '
            
            this.MachineId = null;
            this.MachineName = '';
            this.MachineTypeId = '';
            
            this.ActiveFlagData = [ {value: 'N', label: 'New'} ];
            this.ActiveFlag = this.ActiveFlagData[0].value;

            this.SaveType = 'Add';

            window.$('#machineModal').modal('show');
        },
        editClick(machineData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();       
            this.ModalTitle = 'Edit Machine: '

            this.MachineId = machineData.machine_id;
            this.MachineName = machineData.machine_name;
            this.MachineTypeId = machineData.machine_type;

            if(machineData.active_flag == 'N'){
                machineData.active_flag = 'A';
            }
            this.ActiveFlag = machineData.active_flag;
            this.ActiveFlagData = [ {value: 'A', label: 'Active'}, {value: 'I', label: 'Inactive'} ];

            this.SaveType = 'Edit';

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Machine: ';
            }

            window.$('#machineModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.MachineName == '' || this.MachineName == null){
                this.errorShow('errorMachineName');
            }
            if(this.ActiveFlag == '' || this.ActiveFlag == null){
                this.errorShow('errorActiveFlag');
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const machineData = {
                        machine_name : this.MachineName,
                        machine_type : this.MachineTypeId,
                        active_flag : this.ActiveFlag
                    };

                    var variables = {
                        data : machineData
                    }

                    machineServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#machineModal').modal('hide');
                        this.$swal("Info", response.successSave, "success");
                        this.$props.reload();
                    }).catch(e => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const machineData = {
                        machine_name : this.MachineName,
                        machine_type : this.MachineTypeId,
                        active_flag : this.ActiveFlag
                    };

                    var variables = {
                        id : parseInt(this.MachineId),
                        data : machineData
                    }
                    
                    machineServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#machineModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(e => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    },
}
</script>
